import React from 'react';
import { WithStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Helmet from 'react-helmet';

import Layout from '../components/layout';
import SEO from '../components/seo';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '450px',
      margin: '0 auto'
    },
    formGrid: {
      height: '400px',
      width: '100%'
    },
    submitButton: {
      backgroundColor: '#424B4D'
    }
  });

const ContactPage: React.SFC<ContactPageProps> = ({ classes }) => (
  <Layout pageTitle="Submitted">
    <SEO title="Submitted" />
    <Helmet>
      <meta http-equiv="refresh" content="10; url=https://www.stilsongreene.com/" />
    </Helmet>
    <Grid container style={{ textAlign: 'center', paddingTop: '25px', width: '100%' }}>
      <Grid item style={{ width: '100%' }}>
        <p>
          Thanks for getting in touch!
      </p>
        <p>
          You can expect a reply within 24 hours.
      </p>
        <p>
          Thank you.
      </p>
      </Grid>
    </Grid>
  </Layout>
);

interface ContactPageProps extends WithStyles<typeof styles> {

}

export default withStyles(styles)(ContactPage);